import { createContext, useContext, useState } from 'react';
import { getAllSessions } from '../api/recheckFunctions';

const RecheckContext = createContext();

export const useRecheck = () => {
	return useContext(RecheckContext);
};

export const RecheckProvider = ({ children }) => {
	const [sessions, setSessions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [filter, setFilter] = useState(null);
	const [showCompletedSessions, setShowCompletedSessions] = useState(false);
	const [showClosedSessions, setShowClosedSessions] = useState(false);
	const [dateTimeFilter, setDateTimeFilter] = useState({
		start: null,
		end: null,
		field: null,
	});

	const revalidate = () =>
		getAllSessions(
			{},
			showCompletedSessions,
			showClosedSessions,
			currentPage,
			itemsPerPage,
			dateTimeFilter,
		).then((res) => {
			setShowClosedSessions(false);
			setShowCompletedSessions(false);
			setCurrentPage(1);
			setItemsPerPage(10);
			setDateTimeFilter({ start: null, end: null, field: null });
			setFilter(null);
			setSessions(res);
		});

	return (
		<RecheckContext.Provider
			value={{
				sessions,
				setSessions,
				revalidate,
				currentPage,
				setCurrentPage,
				itemsPerPage,
				setItemsPerPage,
				setFilter,
				filter,
				showClosedSessions,
				setShowClosedSessions,
				showCompletedSessions,
				setShowCompletedSessions,
				setFilter,
				dateTimeFilter,
				setDateTimeFilter,
			}}
		>
			{children}
		</RecheckContext.Provider>
	);
};
