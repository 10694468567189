import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from './datatable-styles';
import { dateFormatMap } from '../../helper/IbisUser';
import { DateTimePicker } from './DateTimePicker';
import { getLoggedUser } from '../../api/authFunctions';

const SessionsDataTable = ({
	data,
	columns,
	title,
	rowClickFunction,
	conditionalRowStyles,
	defaultSortFieldId,
	defaultSortAsc,
	emptyRecordsMessage,
	totalRows,
	itemsPerPage,
	currentPage,
	onPageChange,
	onRowsPerPageChange,
	onFilterChange,
	onDateTimeFilterChange,
	onSort,
	filterOptions,
}) => {
	const [filterCategory, setFilterCategory] = useState('');
	const [filterText, setFilterText] = useState('');
	const [dateFormat, setDateFormat] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [startTime, setStartTime] = useState('00:00');
	const [endTime, setEndTime] = useState('23:59');
	const selectedFilter = filterOptions.find(
		(option) => option.field === filterCategory,
	);

	const resetFilters = useCallback(() => {
		setFilterCategory('');
		setFilterText('');
		setStartDate(null);
		setEndDate(null);
		setStartTime('00:00');
		setEndTime('23:59');
		onFilterChange('', '');
		onDateTimeFilterChange(null, null, '');
	}, [onFilterChange, onDateTimeFilterChange]);

	useEffect(() => {
		if (dateFormat) return;
		getLoggedUser().then((res) => {
			setDateFormat(dateFormatMap[res.company_info.dateformat] || 'dd/MM/yyyy');
		});
	}, [dateFormat]);

	const handleFilterChange = useCallback(() => {
		resetFilters();
		if (selectedFilter?.type === 'datetime') {
			const start = new Date(`${startDate.toDateString()} ${startTime}`);
			const end = new Date(`${endDate.toDateString()} ${endTime}`);
			onDateTimeFilterChange(
				start.getTime(),
				end.getTime(),
				selectedFilter?.field,
			);
		} else {
			onFilterChange(filterCategory, filterText);
		}
	}, [
		filterCategory,
		filterText,
		onFilterChange,
		endDate,
		endTime,
		onDateTimeFilterChange,
		selectedFilter,
		startDate,
		startTime,
	]);

	const NoRecords = () => {
		return (
			emptyRecordsMessage || `No ${title?.toLowerCase() || 'records'} found.`
		);
	};

	const handleSort = (column, sortDirection) => {
		if (onSort) {
			onSort(column, sortDirection);
		}
	};

	const isFilterButtonDisabled = () => {
		if (selectedFilter?.type === 'datetime') {
			return !startDate || !endDate;
		}
		return !filterCategory || (filterCategory && !filterText);
	};

	const renderFilterInput = () => {
		const selectedFilter = filterOptions.find(
			(option) => option.field === filterCategory,
		);

		switch (selectedFilter?.type) {
			case 'datetime':
				return (
					<div className='d-flex'>
						<DateTimePicker
							label='Start Date...'
							selected={startDate}
							onChange={setStartDate}
							time={startTime}
							onTimeChange={(e) => setStartTime(e.target.value)}
							dateFormat={dateFormat}
						/>
						<DateTimePicker
							label='End Date...'
							selected={endDate}
							onChange={setEndDate}
							time={endTime}
							onTimeChange={(e) => setEndTime(e.target.value)}
							minDate={startDate}
							dateFormat={dateFormat}
						/>
					</div>
				);
			default:
				return (
					<input
						type='text'
						name='filter-text'
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
						style={{
							height: '30px',
							padding: '0.25rem',
							borderRadius: '2px',
						}}
					/>
				);
		}
	};

	return (
		<>
			<div className='d-flex justify-content-end'>
				<div
					className='d-flex flex-column'
					style={{ margin: '0rem 1.25rem 1.25rem 3px' }}
				>
					<label htmlFor='filter-select' className='mb-0'>
						Filter by...
					</label>
					<select
						style={{
							width: '15vw',
							height: '30px',
							padding: '0.25rem',
							borderRadius: '2px',
						}}
						name='filter-select'
						value={filterCategory}
						onChange={(e) => setFilterCategory(e.target.value)}
					>
						<option value=''>Select a field</option>
						{filterOptions.map((option) => (
							<option key={option.field} value={option.field}>
								{option.name}
							</option>
						))}
					</select>
				</div>
				<div
					className='d-flex flex-column'
					style={{ margin: '0rem 0rem 1.25rem 3px' }}
				>
					<label htmlFor='filter-input' className='mb-0'>
						{selectedFilter?.type !== 'datetime' && 'Filter term...'}
					</label>
					{renderFilterInput()}
					<div className='d-flex justify-content-end mt-2'>
						<button
							type='button'
							className='btn btn-ibis mr-2'
							onClick={handleFilterChange}
							disabled={isFilterButtonDisabled()}
						>
							Filter
						</button>
						<button
							type='button'
							className='btn btn-secondary'
							onClick={resetFilters}
						>
							Reset
						</button>
					</div>
				</div>
			</div>
			<div className='card shadow mb-5'>
				<div className='card-header bg-primary-ibis py-3'>
					<h6 className='m-0 font-weight-bold text-white'>{title}</h6>
				</div>
				<div className='card-body'>
					<DataTable
						data={data}
						columns={columns}
						customStyles={customStyles}
						noDataComponent={<NoRecords />}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						paginationDefaultPage={currentPage}
						paginationPerPage={itemsPerPage}
						highlightOnHover={Boolean(rowClickFunction)}
						pointerOnHover={Boolean(rowClickFunction)}
						onRowClicked={rowClickFunction}
						striped={data?.length > 1}
						conditionalRowStyles={conditionalRowStyles}
						defaultSortFieldId={defaultSortFieldId}
						defaultSortAsc={defaultSortAsc ?? true}
						onChangePage={onPageChange}
						onChangeRowsPerPage={onRowsPerPageChange}
						onSort={handleSort}
						sortServer
					/>
				</div>
			</div>
		</>
	);
};

export default SessionsDataTable;
