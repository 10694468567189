import { useState } from 'react';

const useSort = (initialSortField, initialSortDirection) => {
	const [sortField, setSortField] = useState(initialSortField);
	const [sortDirection, setSortDirection] = useState(initialSortDirection);

	const handleSort = (column, direction) => {
		setSortField(column.sortField);
		setSortDirection(direction);
	};

	return {
		sortField,
		sortDirection,
		handleSort,
	};
};

export default useSort;
