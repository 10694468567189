import { useEffect, useState, useCallback } from 'react';
import { getAllSessions } from '../../api/recheckFunctions';
import { useRecheck } from '../../context/recheckContext';
import RecheckSessionsList from './RecheckSessionsList';
import ToggleSwitch from './ToggleSwitch';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

const RecheckSessions = () => {
	const {
		sessions: allSessions,
		setSessions: setAllSessions,
		currentPage,
		setCurrentPage,
		itemsPerPage,
		setItemsPerPage,
		filter,
		setFilter,
		showCompletedSessions,
		showClosedSessions,
		setShowCompletedSessions,
		setShowClosedSessions,
		dateTimeFilter,
		setDateTimeFilter,
	} = useRecheck();
	const { handleRowsPerPageChange, handlePageChange } = usePagination();
	const { sortField, sortDirection, handleSort } = useSort();
	const [shouldFetchAllResults, setShouldFetchAllResults] = useState(true);

	const handleFilterChange = (name, value) => {
		setCurrentPage(1);
		setItemsPerPage(10);
		setShouldFetchAllResults(true);
		setFilter({ name, value });
	};

	const handleDateTimeFilterChange = (start, end, field = 'datetime') => {
		setCurrentPage(1);
		setItemsPerPage(10);
		setShouldFetchAllResults(true);
		setDateTimeFilter({ start, end, field });
	};

	const fetchSessions = useCallback(async () => {
		const apiFilters = {};

		if (shouldFetchAllResults === false) {
			apiFilters.show_completed = showCompletedSessions;
			apiFilters.show_closed = showClosedSessions;
			if (sortField)
				apiFilters.sort =
					sortDirection === 'desc' ? `-${sortField}` : sortField;
			if (filter?.name && filter?.value) apiFilters[filter.name] = filter.value;
			if (dateTimeFilter) {
				apiFilters[`start_${dateTimeFilter.field}`] = dateTimeFilter.start;
				apiFilters[`end_${dateTimeFilter.field}`] = dateTimeFilter.end;
			}
			apiFilters.page = currentPage;
			apiFilters.page_size = itemsPerPage;
		}

		const res = await getAllSessions(
			apiFilters,
			showCompletedSessions,
			showClosedSessions,
			currentPage,
			itemsPerPage,
			dateTimeFilter,
		);
		setAllSessions(res);
		setShouldFetchAllResults(false);
	}, [
		currentPage,
		itemsPerPage,
		filter,
		showCompletedSessions,
		showClosedSessions,
		setAllSessions,
		sortField,
		sortDirection,
		dateTimeFilter,
		shouldFetchAllResults,
	]);

	useEffect(() => {
		fetchSessions();
	}, [fetchSessions]);

	return (
		<div className='container-fluid'>
			<ToggleSwitch
				id='showCompletedSessionsSwitch'
				checked={showCompletedSessions}
				onChange={() => setShowCompletedSessions(!showCompletedSessions)}
				label='Include Complete Sessions'
			/>
			<ToggleSwitch
				id='showInvalidSessionsSwitch'
				checked={showClosedSessions}
				onChange={() => setShowClosedSessions(!showClosedSessions)}
				label='Include Closed Sessions'
			/>
			<RecheckSessionsList
				itemsPerPage={itemsPerPage}
				sessions={allSessions?.results || []}
				totalRows={allSessions?.count}
				currentPage={currentPage}
				onPageChange={(page) => {
					setCurrentPage(page);
					handlePageChange(page);
				}}
				onRowsPerPageChange={(newRowsPerPage) => {
					setItemsPerPage(newRowsPerPage);
					handleRowsPerPageChange(newRowsPerPage);
				}}
				onFilterChange={handleFilterChange}
				onSort={handleSort}
				onDateTimeFilterChange={handleDateTimeFilterChange}
			/>
		</div>
	);
};

export default RecheckSessions;
