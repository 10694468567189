import { useState } from 'react';

const usePagination = (initialPage = 1, initialRowsPerPage = 10) => {
	const [currentPage, setCurrentPage] = useState(initialPage);
	const [itemsPerPage, setItemsPerPage] = useState(initialRowsPerPage);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setItemsPerPage(newRowsPerPage);
		setCurrentPage(1);
	};

	return {
		currentPage,
		itemsPerPage,
		handlePageChange,
		handleRowsPerPageChange,
	};
};

export default usePagination;
